import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LogRocket from 'logrocket';
import ReactGA from "react-ga4";


if (process.env.NODE_ENV === 'production') {

  LogRocket.init(`${process.env.REACT_APP_appId}`);
  ReactGA.initialize("G-QGV0QX8E5E");
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


/*

 _    _                  _             _ 
| |  | |                (_)           | |
| |  | | __ _ _ __ _ __  _ _ __   __ _| |
| |/\| |/ _` | '__| '_ \| | '_ \ / _` | |
\  /\  / (_| | |  | | | | | | | | (_| |_|
 \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
                                  __/ |  
                                 |___/   

The following FAQ messages will be inserted using dangerouslySetInnerHTML() to allow for HTML formatting!
Any HTML will actually be rendered directly on the page. Be careful with this! Do not leave unclosed tags
and do not leave any malicious code in here or you will be sorry! >:) mwahahaha

*/


export const technicalQuestions = [
    {
        id: 1,
        question: "What is the Partnered Program?",
        answer: "The Partnered Program is a white-label fractional UI/UX Design & Software Development serviceservice.  The subscription allows organizations to incorporate vetted USA based tehnical resources at a fraction of the cost."
    },
    {
        id: 2,
        question: "How can the Partnered Program benefit my organization?",
        answer: "By subscribing to the Partnered Program, you'll unlock access to top-tier project management services that can supercharge your organization's success. With our help, you can complete projects more efficiently, exceed project goals, and propel your business forward. Join the Partnered Program to unlock the full potential of your organization."
    },
    {
        id: 3,
        question: "How does the Partnered Program compare in pricing? ",
        answer: "The Partnered Program offers top-quality project management services with significant cost savings. We are 29% cheaper compared to hiring a W-2 employee. Our commitment to reducing project overhead expenses by an average of 6% further showcases the value of our service. Join the Partnered Program to achieve your project goals while maximizing your return on investment."
    },
    {
        id: 4,
        question: "What types of projects can you help with?",
        answer: "By joining the Partnered Program you gain access to a team of highly skilled project managers with over 30 years of combined experience. Our experts are equipped with the latest tools and possess the critical thinking and analytical skills necessary to tackle even the most complex projects. They will help you streamline your project management processes, identify hidden risks and opportunities, and achieve successful outcomes. Whether you're starting a new project or seeking to optimize existing ones, our team is dedicated to taking your business to the next level."
    },
    {
        id: 5,
        question: "Is there a minimum engagement length?",
        answer: "No, we do not have any required minimum engagement length for our fractional services, but we do offer discounts for 6 & 12 month contracts."
    },
    {
        id: 6,
        question: "What happens if I run out of hours? ",
        answer: "Nothing! There will be no service interuption. Additional hours beyond the allocated monthly limit will be invoiced at our Partnered hourly rate."
    },
    {
        id: 7,
        question: "Can I upgrade or downgrade my plan?",
        answer: "Yes, of course! The Partnered Program is built to scale with your organization and adjust to current company needs. Clients are able to change their plan before the 1st of each month."
    },
    {
        id: 8,
        question: "Can my plan be cancelled at any time? ",
        answer: "Yes, if you would like to cancel your account please email cancel@partneredmg.com with the associated email on the account. Your account will be canceled at the end of the next billing cycle."
    }
]
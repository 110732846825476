import React, { useState } from "react";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

import PricingTable from "./pricingTable";
import FAQ from "./faq";
import CustomPricing from "./customPricing";

const Pricing = () => {
  const { setPathPageView, setTrackPageView } = useTrackingCode();
  setPathPageView("/pricing");
  setTrackPageView();

  const tabs = [
    { name: "Project Management", href: "/pricing", current: false },
    { name: "Technical Resource", href: "/pricing/technical", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [value, setValue] = useState("Project");
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="w-full flex flex-col px-6 container mx-auto font-bold">
        <div className="text-black text-left py-24">
          <h1 className="text-5xl lg:text-center pointer-events-none">
            Partnered Program <br />
          </h1>
          <h2 className="text-4xl pt-4 font-light lg:text-center pointer-events-none">
            Fractional Rates - Maximum Results
          </h2>
          <p className="text-lg font-semibold leading-8 mt-10 pointer-events-none">
            Our fractional project management service is built on transparency
            and trust. We believe that providing clear and concise information
            is key to building a successful relationship with our clients. With
            no hidden fees or surprises, you can expect vetted USA based, PMP certified project
            management resources that are tailored to your unique needs at
            fractional rates.
          </p>
        </div>
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs.find((tab) => tab.current)}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className=" border-gray-200 mb-20">
              <nav className="-mb-px flex space-x-8 justify-center" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? "border-blue text-gold"
                        : "border-transparent text-gray-500 hover:border-blue-300 hover:text-gold",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-3xl  font-bold"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <PricingTable />
      </div>
      <FAQ />
    </div>
  );
};

export default Pricing;
